$(document).foundation();

//Knaupi Yaaay
// function knaupOutCard(){
//     setTimeout(function() {
//         if (atg_map && atg_map.zoomOut)
//             atg_map.zoomOut();
//         else
//             knaupOutCard()
//     },500);
// }
//
// document.addEventListener('DOMContentLoaded',knaupOutCard);